define("ember-radio-button/components/radio-button-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/runloop", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input ...attributes type="radio" checked={{@checked}} aria-checked={{this.checkedStr}} value={{@value}} {{on "change" this.change}} />
  
  */
  {
    "id": "kirPF2iu",
    "block": "[[[11,\"input\"],[17,1],[24,4,\"radio\"],[16,\"checked\",[30,2]],[16,\"aria-checked\",[30,0,[\"checkedStr\"]]],[16,2,[30,3]],[4,[38,1],[\"change\",[30,0,[\"change\"]]],null],[12],[13],[1,\"\\n\"]],[\"&attrs\",\"@checked\",\"@value\"],false,[\"input\",\"on\"]]",
    "moduleName": "ember-radio-button/components/radio-button-input.hbs",
    "isStrictMode": false
  });
  let RadioButtonInputComponent = _exports.default = (_class = class RadioButtonInputComponent extends _component2.default {
    get checkedStr() {
      const checked = this.args.checked;
      if (typeof checked === 'boolean') {
        return checked.toString();
      }
      return null;
    }
    change() {
      if (this.args.groupValue !== this.args.value) {
        // this.set('groupValue', value);
        (0, _runloop.once)(this.args, 'changed', this.args.value);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RadioButtonInputComponent);
});