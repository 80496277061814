define("ember-credit-cards/utils/is-whitelist-keypress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isDigitKeypress;
  const validKeyCodes = [9,
  // Tab
  8,
  // Backspace
  46,
  // Delete
  27,
  // Escape
  13 // Enter
  ];
  function isDigitKeypress(e) {
    var keyCode = e.keyCode || e.which;
    return validKeyCodes.includes(keyCode);
  }
});