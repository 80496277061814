define("ember-cli-bugsnag/utils/errors", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateError = generateError;
  _exports.getContext = getContext;
  function getContext(router) {
    var url = router.currentURL;
    var routeName = router.currentRouteName;
    var firstSegments = routeName.replace('.index', '').replace(/\./g, ' ');
    var prettyRouteName = (0, _string.capitalize)(firstSegments);
    return prettyRouteName + ' (' + routeName + ', ' + url + ')';
  }
  function generateError(cause, stack) {
    var error = new Error(cause);
    error.stack = stack;
    return error;
  }
});