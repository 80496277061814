var serverURL = "http://127.0.0.1:18080/mPrintServer/";
// var serverURL = "https://192.168.85.31:18080/mPrintServer/";

function toHexBinary(s){
	var l=s.length,r=new Array(l),i;
	for(i=0;i<l;i++){
		r[i]=("0"+s.charCodeAt(i).toString(16)).slice(-2)
	}
	return r.join("")
}

function makeResultInquiryData(requestId, responseId, timeout){
	return "{\"RequestID\":"+requestId+",\"ResponseID\":\""+responseId+"\",\"Timeout\":"+timeout+"}";
}

function checkResult(method, strPrinterName, requestId, responseId, _callback) {
	var requestURL = serverURL + strPrinterName +"/checkStatus";

	var xmlHttpCheck = false;
	if (window.XMLHttpRequest) {
		xmlHttpCheck = new XMLHttpRequest();
	}

	var inquiryData = makeResultInquiryData(requestId, responseId, 30);

	xmlHttpCheck.open(method, requestURL, true);
	xmlHttpCheck.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
	xmlHttpCheck.send(inquiryData);
	xmlHttpCheck.onreadystatechange = function() {
		if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 200) {
      var res = JSON.parse(xmlHttpCheck.responseText);
      console.log(xmlHttpCheck.status, res);
			var ret = res.Result;

			if(ret.search("ready") >= 0 || ret.search("progress") >= 0)	{
				checkResult(method, strPrinterName, requestId, responseId, _callback);
			}
			else {
        if (ret == 'Printer connection error') {
          _callback("Allow USB connection and try again.");
  			} else {
          _callback(res.ResponseID + ":"+ ret);  // complete
        }
			}
		}
		else if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 404) {
			_callback("No printers");  // Add printer named 'kiosk' to mPrintServer app
		}
		else if(xmlHttpCheck.readyState == 4) {
			_callback("cannot connect to server"); // Start the mPrintServer App
		}
	}
}

function requestPrint(strPrinterName, strSubmit, _callback) {
	var requestURL = serverURL + strPrinterName;
	var xmlHttpReq = false;

	if (window.XMLHttpRequest) {
		xmlHttpReq = new XMLHttpRequest();
	}

	xmlHttpReq.open('POST', requestURL, true);
  xmlHttpReq.timeout = 10000; // 10 seconds
	xmlHttpReq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
	xmlHttpReq.send(strSubmit);

	xmlHttpReq.onreadystatechange = function() {

		if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 200) {
			var res = JSON.parse(xmlHttpReq.responseText);
			var ret = res.Result;
      console.log(xmlHttpReq.status, res);
			if(ret.search("ready") >= 0 || ret.search("progress") >= 0)	{
				checkResult('POST', strPrinterName, res.RequestID, res.ResponseID, _callback);
			}
			else if(ret.search("duplicated") >= 0) {
        console.log('duplicate', res.Result)
				_callback(res.Result); // duplicate request (we're not using this yet)
			}
		}
		else if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 404) {
			_callback("No printers");  // Add printer named 'kiosk' to mPrintServer app
		}
		else if(xmlHttpReq.readyState == 4) {
			_callback("cannot connect to server"); // Timeout - Start or Re-Start the mPrintServer App
		}
	}
}

function getBrowser() {
	var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return { name: 'IE', version: (tem[1] || '') };
	}
	if (M[1] === 'Chrome') {
		tem = ua.match(/\bOPR|Edge\/(\d+)/)
		if (tem != null) { return { name: 'Opera', version: tem[1] }; }
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
	if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
	return {
		name: M[0],
		version: M[1]
	};
}
