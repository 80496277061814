define("@algonauti/ember-active-storage/model/blob", ["exports", "rsvp", "@algonauti/ember-active-storage/utils/file-checksum", "@glimmer/tracking"], function (_exports, _rsvp, _fileChecksum, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Blob = _exports.default = (_class = class Blob {
    constructor(file, checksum) {
      // Default Values
      _initializerDefineProperty(this, "file", _descriptor, this);
      _defineProperty(this, "checksum", null);
      _defineProperty(this, "id", null);
      _defineProperty(this, "signedId", null);
      _defineProperty(this, "key", null);
      _defineProperty(this, "directUploadData", null);
      this.file = file;
      this.checksum = checksum;
    }

    // Getters
    get name() {
      return this.file.name;
    }
    get type() {
      return this.file.type;
    }
    get size() {
      return this.file.size;
    }
    toString() {
      return `Blob: ${this.name} with checksum ${this.checksum}`;
    }
    slice() {
      return this.file.slice();
    }
    static build(file) {
      return new _rsvp.Promise((resolve, reject) => {
        _fileChecksum.default.MD5(file).then(checksum => {
          const blob = new Blob(file, checksum);
          resolve(blob);
        }, error => {
          reject(error);
        });
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "file", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
});